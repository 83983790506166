import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import loading_img from "../../../images/profile/loading.gif";
import { useHistory, useLocation } from "react-router-dom";

const BetTechComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [renderer, setRenderer] = useState(null); // Store BTRenderer instance
  const sel_Language = localStorage.getItem('selectedLanguage');
  const auth_token = localStorage.getItem('auth_token');
  const selectedCurrency = localStorage.getItem('selectedCurrency');
  useEffect(() => {
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
      language: sel_Language,
    };
    const loadBetTech = async () => {
      try {
        if (auth_token) {
          const response = await axios.post('/sportsbook', data);
          loadBetTechScript(response.data.data.token);
        } else {
          loadBetTechScript(null);
        }
      } catch (error) {
        console.error("Error loading BetTech script:", error);
      }
    };

    loadBetTech();

    return () => {
      // Cleanup script when the component unmounts
      const script = document.getElementById("btrenderer-script");
      if (script) {
        script.remove();
      }
    };
  }, [history]);

  const loadBetTechScript = (token) => {
    if (!document.getElementById("btrenderer-script")) {
      const script = document.createElement("script");
      script.src = "https://ui.invisiblesport.com/bt-renderer.min.js";
      script.async = true;
      script.defer = true;
      script.id = "btrenderer-script";
      script.onload = () => {
        setIsScriptLoaded(true);
        if (window.BTRenderer) {
          const btRendererInstance = new window.BTRenderer().initialize({
            brand_id: "2423510806797881344",
            token: token || "",
            themeName: "demo-red-dark-card",
            lang: sel_Language,
            target: document.getElementById("bettech"),
            minFrameHeight: 700,
            betSlipOffsetTop: 0,
            stickyTop: 0,
            //betSlipOffsetBottom: 0,
            betslipZIndex: 999,
            onRouteChange: function () {
              console.log("on route change", arguments);
            },
            onLogin: function () {
              history.push('?login=true');
            },
            onRegister: function () {
              swal("Warning", "Not enough funds in the account", "warning");
              history.push('/register');
            },
            onSessionRefresh: function () {
              // Avoid using window.location.reload(), handle session refresh in a different way if needed
            },
            onRecharge: function () {
              swal("Warning", "Not enough funds in the account", "warning");
              history.push('/deposit');
            },
            onTokenExpired: function () {
                handleTokenExpired();
            }
          });
          setRenderer(btRendererInstance); // Save the renderer instance for later use
        }
      };
      document.head.appendChild(script);
    }
  };


const handleTokenExpired = () => {
  const auth_token = localStorage.getItem("auth_token");
  const selectedCurrency = localStorage.getItem("selectedCurrency");
  const data = {
    key_s: "eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9",
    currency: selectedCurrency,
    language: "en",
  };
  
  if (auth_token !== null) {
    axios
      .post("/sportsbook", data)
      .then((response) => {
        if (response.data.data.token) {
          console.log("ddddddddddddddddddoooooooooooooooooooonnnnnnnnnnnnnnnneeeeeeeee.");
          renderer.resolve(response.data.data.token); // Update token in the renderer
        } else {
          console.log("Token not nnnnnnnnnnnnnooooooooooooooottttttttttttttffffffffffff");
        }
      
      })
      .catch((error) => {
        console.log("Error refreshing token:", error);
      });
  }
};




    // Handle URL query changes
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
    if (btPathd && renderer) {
      //console.log("Updating options with bt-path:", btPathd);
      // Update the renderer options dynamically without reinitializing the application
      renderer.updateOptions({ url: btPathd });
    }
  }, [location.search, renderer]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
    const query = new URLSearchParams(location.search);
    const btPathd = query.get('bt-path');
      if (!btPathd && location.pathname !==  "/sportsbook") {
        console.log("kill kill kill kill:", btPathd);
        renderer.kill();
      } else if (renderer && renderer.kill) {
        console.log("no kill no kill no kill no kill:", btPathd);
      }
    });

    return () => {
      unlisten(); // Stop listening to route changes when the component unmounts
    };
  }, [history, renderer]);

  return (
    <div>
      <style>
        {`
          @media (max-width: 767px) { /* Mobile view */
            .iframe-container {
              margin:-20px -12px 0px -12px;
              height: 100%;
              overflow: hidden;
            }
          }

          @media (min-width: 768px) and (max-width: 1200px) { /* Tablet and up */
            .iframe-container {
              margin:-25px -34px 0px -34px;
              height: 100%;
              overflow: hidden;
            }
          }

          @media (min-width: 1200px) { /* Tablet and up */
            .iframe-container {
              margin:-50px -34px 0px -34px;
              height: 100%;
              overflow: hidden;
            }
          }
        `}
      </style>
      <div className="iframe-container">
        {isScriptLoaded ? (
          <div
            id="bettech"
            style={{ width: "100%", height: "100%", backgroundColor: "#000000", color: "white", zIndex: 1000 }}
          ></div>
        ) : (
         <div className="d-flex justify-content-center align-items-center vh-100">
            <img src={loading_img} alt="" className="me-3 rounded" width={75} />
         </div>
        )}
      </div>
    </div>
  );
};

export default BetTechComponent;
